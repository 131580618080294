<template>
  <!-- Cards grupos -->
  <b-card-group columns>
    <div v-if="$store.state.group_exist == ''">
      <h4 style="margin: 1rem; margin-bottom: 2rem">Nenhum grupo encontrado</h4>
    </div>
    <div
      v-for="(item, index) in possible_groups"
      :key="'card_' + item.id + '_' + $store.state.active_group.id"
    >
      <b-card
        bg-variant="transparent"
        :border-variant="cards_colors[0].color"
        class="card-app-design"
      >
        <b-row>
          <b-card-title class="mb-75 ml-75">
            <b-badge variant="light-success">
              {{ item.name }}
            </b-badge>
          </b-card-title>

          <div style="flex: auto; text-align: end">
            <b-button
              @click="$store.state.selected_group = item"
              style="padding: 0px; margin-right: 1rem"
              variant="flat-danger"
              class="btn-icon"
              v-b-modal.modal-delete
              v-b-tooltip.hover.v-danger
              title="Apagar grupo"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-row>

        <b-card-text class="font-small-2 mb-2">
          {{ item.description }}
        </b-card-text>

        <div class="design-group">
          <h6 class="section-label">Membros</h6>
          <b-avatar-group size="34px">
            <!-- <b-avatar
              class="pull-up"
              v-for="member in get_members"
              :key="member.id"
              :text="member[0]"
              size="34"
              variant="light-dark"
            /> -->
            <b-avatar
              class="pull-up"
              :text="get_members[0]"
              size="34"
              variant="light-dark"
            />
          </b-avatar-group>
        </div>

        <!-- Valores de cada grupo -->
        <div class="design-planning-wrapper" style="margin-bottom: 0.5rem">
          <div class="design-planning">
            <h6
              v-if="get_device.length != 0"
              class="mb-0"
              style="text-align: left"
            >
              {{ get_device[index].length }}
            </h6>
            <p class="card-text mb-25">Dispositivos</p>
          </div>
          <div class="design-planning">
            <h6 class="mb-0" style="text-align: left" v-if="ready == true">
              <!-- {{ get_members[index].dadosMembersGroup.length }} -->
              1
            </h6>
            <p class="card-text mb-25">Membros</p>
          </div>
        </div>

        <!-- botões -->
        <b-row>
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              style="margin-left: 1vw"
              v-b-modal.modal-edit_group
              @click="
                $store.state.selected_group = item;
                group_name = item.name;
                group_description = item.description;
              "
            >
              Editar grupo
            </b-button>
          </b-col>
          <b-col md="6" style="text-align: right">
            <b-button
              v-if="$store.state.active_group.id == item.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              style="margin-right: 1vw"
              disabled
            >
              Selecionado
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              style="margin-right: 1vw"
              @click="$store.state.active_group = item"
            >
              Selecionar grupo
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <!-- Modal para apagar grupo -->
    <b-modal
      ref="modal-delete"
      id="modal-delete"
      centered
      title="Deletar grupo "
      hide-footer
    >
      <b-card-text>
        <div style="text-align: center">
          <feather-icon
            style="color: rgb(249, 175, 0)"
            icon="AlertCircleIcon"
            size="100"
          />
          <h3 style="margin-top: 0.5rem">
            Deletar {{ $store.state.selected_group.name }}?
          </h3>
          <p>Esta ação não pode ser desfeita</p>
          <div style="margin-top: 2rem" @click="hideModalDelete">
            <b-button
              style="margin-right: 1.5rem"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              @click="confirm_delete()"
            >
              Delete isso!
            </b-button>

            <b-button variant="outline-danger"> Cancelar </b-button>
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <!-- Modal para editar grupo -->
    <b-modal
      ref="edit-modal"
      id="modal-edit_group"
      hide-footer
      centered
      title=" Editar Grupo"
    >
      <b-form-group>
        <label for="email">Nome:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Nome do grupo"
          v-model="group_name"
        />
      </b-form-group>
      <b-form-group>
        <label for="textarea-default">Descrição</label>
        <b-form-textarea
          id="textarea-default"
          placeholder="Descrição do grupo"
          rows="3"
          v-model="group_description"
        />
      </b-form-group>
      <div style="margin-top: 2.5vh; text-align: right" @click="hideModal">
        <b-button
          style="margin-right: 1vw"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
        >
          Fechar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          @click="editar_grupo()"
        >
          Editar grupo
        </b-button>
      </div>
    </b-modal>
  </b-card-group>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BFormGroup,
  BCardText,
  BAvatar,
  BButton,
  BCardGroup,
  BAvatarGroup,
  BBadge,
  BButtonGroup,
  BCol,
  BRow,
  BModal,
  VBTooltip,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cookies from "js-cookie";
import store from "@/store/index";
import axios from "axios";
import api from "@/service/api";

export default {
  components: {
    VBTooltip,
    BCard,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BCardGroup,
    BAvatarGroup,
    BButtonGroup,
    BCol,
    BRow,
    BModal,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BBadge,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    var self = this;

    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }

    if (Cookies.get("token_api") == undefined) {
      top.location.href = "/login";
    }

    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }
    
    self.requests();
  },
  data() {
    return {
      avatar_letter: [],
      cont: 0,
      ready: false,
      group_name: store.state.selected_group.name,
      group_description: store.state.selected_group.description,
      possible_groups: [],
      get_device: [],
      get_members: [],
      cards_colors: [
        {
          color: "secondary",
        },
        {
          color: "success",
        },
      ],
    };
  },
  computed: {},
  methods: {
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },
    async confirm_delete() {
      var self = this;

      if (this.possible_groups.length > 1) {
        var params = store.state.selected_group.id;
        await axios
          .delete("https://tekie.cloud:3031/group?id=" + params, {
            headers: {
              Authorization: this.$store.state.token_api,
            },
          })

          .then(function (response) {
            self.requests();
            let title = "Notificação";
            let icon = "BellIcon";
            let text = "Grupo deletado!";
            let variant = "success";
            self.$toast({
              component: ToastificationContent,
              props: {
                title,
                icon,
                text,
                variant,
              },
            });
          })
          .catch(function (error) {
            //error);
            //"Deu erro");
          });
        store.state.group_exist = await api.get_groups();
        store.state.active_group = store.state.group_exist[0];
      } else {
        let title = "Notificação";
        let icon = "BellIcon";
        let text = "Você precisa de no mínimo 1 grupo!";
        let variant = "danger";
        self.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        });
      }
    },
    async requests() {
      var cont = 0;
      var self = this;

      self.ready = false;
      self.possible_groups = await api.get_groups();
      store.state.group_exist = self.possible_groups;
      store.state.active_group = store.state.group_exist.find(
        (find_data) => find_data.id == store.state.active_group.id
      );
      let group_param = "?";
      self.possible_groups.forEach((element) => {
        group_param += "&idGroup=" + element.id;
      });

      var possible_devices = await api.find_devices(group_param);
      try{
        for (let i = 0; i < self.possible_groups.length; i++) {
          self.get_device[i] = possible_devices.filter(
            (e) => e.groupId == self.possible_groups[i].id
          );
        }
      } catch {
        self.get_device = [];
      }
      
      self.get_members = await api.get_members();
      self.ready = true;
    },

    hideModal() {
      this.$refs["edit-modal"].hide();
    },
    showToast(variant, title, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async editar_grupo() {
      var self = this;
      let data = {
        id: store.state.selected_group.id,
        name: self.group_name,
        description: self.group_description,
      };

      let response = await api.patch_group(data);
      if (response.status == 200 || response.status == 201) {
        let title = "Notificação";
        let icon = "BellIcon";
        let text = "Grupo editado com sucesso!";
        let variant = "success";
        self.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        });
        self.requests();
      } else {
        let title = "Notificação";
        let icon = "BellIcon";
        let text = "Falha ao tentar atualizar os dados";
        let variant = "danger";
        self.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
[dir] .modal .modal-header {
  background-color: #f8f8f8
}
</style>
